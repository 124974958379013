<template>
  <a-drawer
    :closable="false"
    :visible="visible"
    placement="right"
    title="添加子账号"
    width="600px"
    @close="closeDrawer"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="layout">
        <a-form-item label="手机号">
          <a-input
            v-decorator="['phone', { rules: [{ required: true, message: '请输入手机号!' }] }]"
            type="number" />
        </a-form-item>
        <a-form-item label="密码">
          <a-input
            v-decorator="['password', { rules: [{ required: true, message: '请输入密码!' }] }]"
            type="password" />
        </a-form-item>
        <a-form-item label="邮箱">
          <a-input v-decorator="['email', { rules: [{ required: true, message: '请输入邮箱' }] }]" />
        </a-form-item>
        <a-form-item label="头像">
          <UploadImg v-decorator="['avatar', { rules: [{required: true, message: '请上传大小不超过2M的图片，支持JPG/PNG格式'}] }]"></UploadImg>
        </a-form-item>
        <a-form-item :required="true" label="权限配置">
          <a-tree
            ref="tree"
            v-model="permissions"
            :checkable="true"
            :replaceFields="defaultProps"
            :treeData="menuTree"
          >

          </a-tree>
        </a-form-item>
      </a-form>
    </a-spin>
    <div class="action flex-row flex-col-center flex-row-between">
      <div>
        <a-button style="margin-right: 8px" @click="closeDrawer">
          取消
        </a-button>
        <a-button type="primary" @click="handleOk">
          添加
        </a-button>
      </div>
    </div>
  </a-drawer>
</template>

<script>
import showDrawerMixin from '@/mixin/ShowDrawerMixin'
import { addAccount, getMenuTree } from '@/api/user'
import UploadImg from '@/components/UpLoadImg/UploadImg.vue'
import cloneDeep from 'lodash.clonedeep'
/**
 * @author  XuHongli
 * @date  2023/3/7 20:10
 * @version 1.0
 * @description AddSubAccountDrawer.vue
 */
export default {
  name: 'AddSubAccountDrawer',
  components: { UploadImg },
  mixins: [ showDrawerMixin ],
  data () {
    return {
      loading: true,
      form: this.$form.createForm(this),
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 14 },
      },
      menuTree: [],
      defaultProps: {
        children: 'children',
        title: 'label',
        key: 'id'
      },
      permissions: [],
      leafNodes: [],
      leafNodesIds: []
    }
  },
  created () {
    getMenuTree().then((res) => {
      this.menuTree = res.data
      this.loading = false
      this.parseTreeJson(this.menuTree)
    })
  },
  methods: {
    isLeafNode (array) {
      const result = []
      array.forEach((item) => {
        if (this.leafNodesIds.indexOf(item) > -1) {
          result.push(item)
        }
      })
      return result
    },
    parseTreeJson (array) {
      for (let index = 0; index < array.length; index++) {
        const element = array[index]
        // 1.判断element.children是对象
        if (element.children && typeof (element.children) === 'object') {
          this.parseTreeJson(element.children)
        } else {
          // 判断是否为子节点
          if (element.children === null) {
            // 获得符合的 node
            this.leafNodes.push(element)
            // 获得符合的 nodeId
            this.leafNodesIds.push(element.id)
          }
        }
      }
    },
    addDefaultData(obj) {
      let _v = cloneDeep(obj)
      _v.organId = '1562534641132163073'
      _v.username = obj.phone
      _v.nickName = obj.phone
      return _v
    },
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const queryData = Object.assign(
            this.addDefaultData(values),
            {permissions: this.permissions.concat(this.$refs.tree.$children[0]._data._halfCheckedKeys)},
            {lockFlag: 0}
          )
          console.log(queryData)
          addAccount(queryData).then((res) => {
            this.$notification.success({
              message: '新增成功',
              description: '您已成功添加子账号'
            })
            this.$emit('ok', res)
            this.closeDrawer()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.action {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  left: 0;
  background: #fff;
  flex-direction: row-reverse;
  border-radius: 0 0 4px 4px;
}
>>> .ant-drawer-body{
  padding-bottom: 40px;
}

</style>
