<template>
  <a-modal
    :centered="true"
    :visible="visible"
    title="修改权限"
    @cancel="closeModal"
    @ok="handleOk"
  >
    <a-form ref="form" v-bind="layout">
      <a-form-item :required="true" label="权限">
        <a-tree
          ref="tree"
          v-model="permissions"
          :checkable="true"
          :replaceFields="defaultProps"
          :treeData="menuTree"
        >

        </a-tree>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import ShowModalMixin from '@/mixin/ShowModalMixin'
import { fetchSubUserTree, getMenuTree, permissionUserUpdate } from '@/api/user'

/**
 * @author  XuHongli
 * @date  2023/3/7 21:38
 * @version 1.0
 * @description EditSubRoles.vue
 */
export default {
  name: 'EditSubRoles',
  mixins: [ ShowModalMixin ],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 14 },
      },
      defaultProps: {
        children: 'children',
        title: 'label',
        key: 'id'
      },
      permissions: [],
      menuTree: [],
      leafNodes: [],
      leafNodesIds: []
    }
  },
  created () {
    getMenuTree().then((res) => {
      this.menuTree = res.data
      this.loading = false
      this.parseTreeJson(this.menuTree)
      fetchSubUserTree(this.id).then((res) => {
        this.permissions = this.isLeafNode(res.data)
      })
    })
  },
  methods: {
    handleOk() {
      if (this.permissions.length === 0) {
        this.$notification.error({
          message: '修改失败',
          description: '请选择要修改的权限'
        })
      } else {
        const queryData = {
          userId: this.id,
          menuId: this.permissions.concat(this.$refs.tree.$children[0]._data._halfCheckedKeys).join(',')
        }
        permissionUserUpdate(queryData).then(() => {
          this.$notification.success({
            message: '修改成功',
            description: '您已经成功修改此账号的权限'
          })
          this.closeModal()
        })
      }
    },
    isLeafNode (array) {
      const result = []
      array.forEach((item) => {
        if (this.leafNodesIds.indexOf(item) > -1) {
          result.push(item)
        }
      })
      return result
    },
    parseTreeJson (array) {
      for (let index = 0; index < array.length; index++) {
        const element = array[index]
        // 1.判断element.children是对象
        if (element.children && typeof (element.children) === 'object') {
          this.parseTreeJson(element.children)
        } else {
          // 判断是否为子节点
          if (element.children === null) {
            // 获得符合的 node
            this.leafNodes.push(element)
            // 获得符合的 nodeId
            this.leafNodesIds.push(element.id)
          }
        }
      }
    },
  }
}
</script>

<style scoped>

</style>
