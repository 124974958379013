<template>
  <a-modal
    :centered="true"
    :visible="visible"
    title="修改子账号密码"
    @cancel="closeModal"
    @ok="handleOk"
  >
    <a-form ref="form" :form="form" v-bind="layout">
      <a-form-item label="密码">
        <a-input
          type="password"
          v-decorator="['password', { rules: [{ required: true, message: '请输入密码!' }] }]" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import ShowModalMixin from '@/mixin/ShowModalMixin'
import { editSubUserPassword } from '@/api/user'

/**
 * @author  XuHongli
 * @date  2023/3/7 21:23
 * @version 1.0
 * @description EditSubAccountPassword.vue
 */
export default {
  name: 'EditSubAccountPassword',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  mixins: [ ShowModalMixin ],
  data() {
    return {
      form: this.$form.createForm(this, { name: 'RefundForm' }),
      layout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 14 },
      },
    }
  },
  methods: {
    handleOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          editSubUserPassword(Object.assign({ id: this.id }, values)).then((res) => {
            this.$notification.success({
              message: '修改成功',
              description: '您已经成功修改此账号密码'
            })
            this.$emit('ok', res.data)
            this.closeModal()
          })
        }
      })
    }
  }

}
</script>

<style scoped>

</style>
