<template>
  <div class="account">

    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="10">
          <div style="float: left" class="mr-10 mb-10">
            <a-button type="primary" @click="handleAdd" size="small">添加</a-button>
          </div>
        </a-row>
      </a-form>
    </div>
    <STable
      ref="table"
      :alert="true"
      :columns="columns"
      :data="loadData"
      rowKey="id"
      showPagination="auto"
      size="middle"
    >
<!--      <span slot="lockFlag" slot-scope="text">-->
<!--        <a-tag-->
<!--          :color="Boolean(text) ? 'geekblue' : 'red'"-->
<!--        >{{ Boolean(text) ? '是' : '否' }}</a-tag>-->
<!--      </span>-->
      <div slot="avatar" slot-scope="text">
        <img
          :src="text"
          alt=""
          style="width: 80px;height: auto;cursor: pointer"
          @click="previewImage = text;previewVisible=true">
      </div>
      <span slot="action" slot-scope="text, record">
        <a style="color: dodgerblue" @click="handleEditPassword(record)">
          <a-icon type="edit" />
          修改密码
        </a>
        <a-divider type="vertical" />
        <a style="color: dodgerblue" @click="handleEditRoles(record)">
          <a-icon type="edit" />
          修改权限
        </a>
        <a-divider type="vertical" />
        <a style="color: dodgerblue" @click="handleDelete(record)">
          <a-icon type="delete" />
          删除
        </a>
      </span>
    </STable>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false" :centered="true" width="50%">
      <img alt="example" style="width: 100%;height: auto" :src="previewImage"/>
    </a-modal>
    <EditSubRoles :id="selectId" v-model="showEditSubRoles" v-if="showEditSubRoles"></EditSubRoles>
    <EditSubAccountPassword :id="selectId" v-model="showEditSubAccountPassword" v-if="showEditSubAccountPassword" @ok="() => this.$refs.table.refresh(true)"></EditSubAccountPassword>
    <AddSubAccountDrawer v-model="showAccountDrawer" v-if="showAccountDrawer" @ok="() => this.$refs.table.refresh(true)"></AddSubAccountDrawer>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2023/3/7 20:02
 * @version 1.0
 * @description index.vue
 */
import { delAccount, GetSuber } from '@/api/user'
import STable from '@/components/Table'
import { columns } from './columns'
import AddSubAccountDrawer from '@/views/User/bypassAccount/AddSubAccountDrawer.vue'
import EditSubAccountPassword from '@/views/User/bypassAccount/EditSubAccountPassword.vue'
import EditSubRoles from '@/views/User/bypassAccount/EditSubRoles.vue'
export default {
  name: 'ByPassAccount',
  components: {
    EditSubRoles,
    EditSubAccountPassword,
    AddSubAccountDrawer,
    STable
  },
  data () {
    return {
      previewVisible: false,
      previewImage: '',
      showAccountDrawer: false,
      showEditSubAccountPassword: false,
      showEditSubRoles: false,
      queryParam: {},
      columns,
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return GetSuber(requestParameters).then((res) => {
          return res.data
        })
      },
      selectId: '',
    }
  },
  methods: {
    handleEditRoles (row) {
      this.selectId = row.id
      this.showEditSubRoles = true
    },
    handleEditPassword (row) {
      this.selectId = row.id
      this.showEditSubAccountPassword = true
    },
    handleDelete (row) {
      this.$confirm('此操作将永久删除该用户(用户名:' + row.username + '), 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delAccount(row.id).then((res) => {
          this.$notification.success({
            message: '删除成功',
            description: '您成功删除账号' + row.username
          })
          this.$refs.table.refresh(true)
        })
      })
    },
    handleAdd () {
      this.showAccountDrawer = true
    }
  }
}
</script>

<style scoped>
.account{
  background-color: #fff;
  padding: 10px 20px;
}
</style>
